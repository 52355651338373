export interface Project {
  id: string;
  hirerId: string;
  title: string;
  type: string;
  media: string[];
  description: string;
  shootLocation: string;
  state?: string;
  isNotConsecutiveDate: boolean;
  shootDates: string[];
  shootDate: {
    from: string;
    to: string;
  };
  createdAt: string;
  closingDate: string;
  contractual: boolean;
  mediaUsageCountries: string[];
  usageDuration: {
    perpetual: boolean;
    duration?: string;
  };
  paymentTerm: {
    period: string;
    value: number;
  };
  allowances: string[];
  availableRoles?: number;
  totalApplicants?: number;
  isTentative?: boolean;
  isActive?: 'active' | 'inactive';
  lastUpdatedAt?: string;
  publishedAt?: string;
}

export type RoleType = 'main' | 'featured' | 'featured extra' | 'background';

export type Role = {
  id: string;
  projectId: string;
  name: string;
  description: string;
  type: RoleType;
  applicants?: Record<string, Applicant>;
  gender: string;
  ageFrom: number;
  ageTo: number;
  ethnicity?: string;
  race?: string;
  raceEthnicity?: string[];
  payment: number;
  languages: string[];
  countNeeded: number;
  totalApplicants?: number;
  shootDates?: string[];
  allowances?: Array<{
    allowanceType: string;
    hourRate?: number;
    maxAmount?: number;
    amount?: number;
  }>;
};

type Experience = {
  link: string;
  name: string;
  role: string;
  type: string;
  year: number;
  createdAt?: string;
};

export type MediaFile = {
  original: File;
  thumbnail: File;
};

export type GalleryItem = {
  original: string;
  thumbnail: string;
  type: 'photo' | 'video';
};

export type MediaManagerItem = {
  file?: MediaFile;
  url: GalleryItem;
  isNew: boolean;
};

export type Talent = {
  id: string;
  bio: string;
  bodyType: string;
  chestSize: number;
  dob: string;
  countryOfOrigin: string;
  ethnicity: string;
  experiences: Experience[];
  eyeColor: string;
  facebookId: string;
  xiaohongshuId: string;
  gallery: GalleryItem[];
  gender: string;
  hairColor: string;
  height: number;
  hipSize: number;
  instagramId: string;
  languages: string[];
  name: string;
  pantSize: string;
  playableAge: {
    from: number;
    to: number;
  };
  playableAgeFrom: number;
  playableAgeTo: number;
  phoneNumber?: string;
  phoneNumberPrefix?: string;
  profilePhoto: string;
  profilePhotoThumbnail: string;
  race: string;
  roles: string[];
  shirtSize: number;
  shoeSize: number;
  skills: string[];
  state: string;
  douyinId: string;
  tiktokId: string;
  twitterId: string;
  username: string;
  waistSize: number;
  weight: number;
  youtubeId: string;
  claimed: boolean;
  agency: {
    name: string;
    email: string;
    phoneNumber: string;
    website: string;
  };
  kol: {
    focusArea: string[];
  };
  acceptRoles: string[];
  minimumDailyWage: number;
  douyinFollowers: string;
  facebookFollowers: string;
  instaFollowers: string;
  instaPosts: string;
  tiktokFollowers: string;
  tiktokLikes: string;
  ytSubscribers: string;
  ytVideos: string;
  xhsFollowers: string;
  xhsLikesFavs: string;
  twitterFolowers: string;
  reviewSocialLinks: boolean;
  lastUpdatedDateFollowers: string;
  reviewPaymentMethod: boolean;
  paymentMethod: {
    beneficieryName: string;
    channelCode: string;
  };
};

export type Applicant = Omit<Talent, 'id'> & {
  id: string; // application id
  talentId: string;
  roleId: string;
  status: string;
  appliedAt: string;
  statusUpdatedAt: string;
  isRecommended: boolean;
  recommendOrder: number;
};

export type JobHistory = Omit<Partial<Talent>, 'id'> & {
  project: Project;
  role: Role;
  talentId: string;
  roleId: string;
  status?: string;
  rolePayment: number;
  appliedAt: string;
  statusUpdatedAt?: string;
  isNotSelected?: boolean;
  isNotSelectedTimestamp?: string;
  agreeStatedRate?: boolean;
  agreeStatedRateTimestamp?: string;
};

export type Settings = {
  hasActivateTelegram: boolean;
  isTelegramNotificationOn: boolean;
  isWhatsappNotificationOn?: boolean;
  isWhatsappVerified?: boolean;
  whatsappPhoneNumberPrefix?: string;
  whatsappPhoneNumber?: string;
};

export type AccessTime = {
  subscription: PushSubscription;
  isPWA: boolean;
};

export type Activity = {
  activityId: string;
  hirerId: string;
  talentId: string;
  roleId: string;
  applicationId: string;
  projectId?: string;
  createdAt: string;
  lastUpdateAt?: string;
  actionType: string;
  replyStatus?: 'PENDING' | 'SCHEDULED';
  scheduleId?: string;
  scheduledDatetime?: string;
  talentPhoneNumber?: string;
  talentPhoneNumberPrefix?: string;
  projectTitle: string;
  roleName: string;
  withdrawReason?: string;
  duration?: { period: 'minute' | 'hour'; value: number };
  castingType?: 'physical' | 'virtual' | 'self';
  castingBrief?: string;
  virtualMeetingLink?: string;
  picContact?: string;
  picContactPrefix?: string;
  castingLocation?: string;
  castingLocationLng?: number;
  castingLocationLat?: number;
  selfCastingVideo?: string;
  submitDeadline?: string;
  updateLog?: string[];
  cancelReason?: string;
};

export type Schedule = {
  scheduleId: string;
  hirerId: string;
  scheduleName?: string;
  slots: Record<string, { start: string; end: string }[]>;
  scheduleLastDate: string;
};

export type HirerCompany = {
  createdAt: string;
  lastUpdatedAt?: string;
  hirerId: string;
  companyCountry: string;
  companyAddress: string;
  companyAddressCity: string;
  companyAddressPostcode: number;
  companyAddressState: string;
  companyContactNumber: string;
  companyContactNumberPrefix: string;
  companyEmail: string;
  companyName: string;
  companySsmRegNumber?: string | null;
  profilePic?: string;
  profilePicThumbnail?: string;
  approvalStatus?: 'approved' | 'rejected';
  describeAs?: string;
  describeAsOthers?: string;
  companyWebsite?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  tiktok?: string;
  xiaohongshu?: string;
};

export type HirerUser = {
  createdAt: string;
  userId: string;
  hirerId: string;
  name: string;
  email: string;
  phoneNumber: string;
  phoneNumberPrefix: string;
  isDeleted?: boolean;
  status?: 'verified';
};

export type PendingRating = {
  hirerId: string;
  talentId: string;
  roleId: string;
  projectId: string;
  shootingDate: string;
};

export type RatingMetrics = {
  punctuality: number;
  professionalism: number;
  responsiveness: number;
  dialogueMastery: number;
  profileRepresentation: number;
  overallPerformance: number;
};

export type Rating = Partial<RatingMetrics> & {
  talentId: string;
  roleId: string;
  hirerId: string;
  userId: string;
  projectId: string;
  ratingId: string;
  createdAt: string;
  updatedAt?: string;
  status: 'PENDING' | 'PUBLISHED' | 'UNPUBLISHED';
  authorCompanyName: string;
  authorUserName: string;
  authorProfilePicThumb?: string;
  hireAgain: boolean;
  writtenReview?: string;
  comments: Comment[];
};

export type Comment = {
  ratingId: string;
  commentId: string;
  createdAt: string;
  updatedAt?: string;
  comment: string;
  authorType: 'talent' | 'hirer';
  authorName: string;
  talentId: string;
  roleId: string;
  hirerId?: string;
  userId?: string;
  authorProfilePicThumb?: string;
  replies: Reply[];
};

export type Reply = Omit<Comment, 'comment' | 'replies'> & {
  replyId: string;
  reply: string;
};

export type Prev30daysStats = RatingMetrics & {
  cutOffTimestamp: string;
  totalMetricsCount: number;
};

export type RatingStats = RatingMetrics & {
  talentId: string;
  createdAt: string;
  updatedAt?: string;
  totalRatingCount: number;
  totalMetricsCount: number;
  totalWrittenReviewCount: number;
  hireAgain: number;
  averageRating: number;
  prev30days: Prev30daysStats;
};

export type InvoiceInfoBase = {
  name: string;
  address?: string;
  city?: string;
  state?: string;
  phoneNumber?: string;
  email?: string;
};

export type InvoiceItemBase = {
  description: string;
  amount: number;
  attachments?: Array<{ url: string }>;
};

export type InvoiceBase = {
  id?: string;
  projectId?: string;
  from?: InvoiceInfoBase;
  billTo?: InvoiceInfoBase;
  items?: InvoiceItemBase[];
  createdAt?: string;
  updatedAt?: string;
};

export type PaymentMethod = {
  beneficieryName: string;
  channelCode: string;
  accountNumber: string;
};

export enum SelfBilledInvoiceStatus {
  DRAFT = 'DRAFT',
  PENDING_PAYER_MAKE_PAYMENT = 'PENDING_PAYER_MAKE_PAYMENT',
  PENDING_PAYMENT_GATEWAY_WEBHOOK = 'PENDING_PAYMENT_GATEWAY_WEBHOOK',
  PAID = 'PAID',
}

export type SelfBilledInvoice = InvoiceBase & {
  payeeId?: string;
  status?: SelfBilledInvoiceStatus;
  paymentMethod?: PaymentMethod;
  paymentGatewayPayoutId?: string;
};

export enum RequestPaymentInvoiceStatus {
  DRAFT = 'DRAFT',
  PENDING_PAYER_REVIEW = 'PENDING_PAYER_REVIEW',
  PENDING_PAYER_MAKE_PAYMENT = 'PENDING_PAYER_MAKE_PAYMENT',
  PENDING_PAYMENT_GATEWAY_WEBHOOK = 'PENDING_PAYMENT_GATEWAY_WEBHOOK',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
}
export type RequestPaymentInvoice = InvoiceBase & {
  payerId?: string;
  status?: RequestPaymentInvoiceStatus;
  paymentGatewayInvoiceId?: string;
  paymentGatewayInvoiceUrl?: string;
};
