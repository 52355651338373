export const checkDifferentState = (
  projectState?: string,
  talentState?: string
) => {
  if (!projectState || !talentState) return false;
  if ([projectState, talentState].includes('Other Country')) return false;
  if (
    projectState === 'W.P Kuala Lumpur / Selangor' &&
    ['W.P Kuala Lumpur', 'Selangor'].includes(talentState)
  )
    return false;

  return projectState !== talentState;
};
